import {
  getActiveBulletPrice,
  type ActiveBulletPriceDataType,
} from '@deorderbook/backend-api'

export const useDerivedActiveBulletPrice = () => {
  const fetchActiveBulletPrice = async (): Promise<
    Array<ActiveBulletPriceDataType> | undefined
  > => {
    const res = await getActiveBulletPrice()
    return res
  }

  const { data, execute } = useCacheableAsyncData(fetchActiveBulletPrice)

  return {
    data,
    refreshData: () => execute(undefined, true),
  }
}
