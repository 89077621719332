import { type AsyncDataOptions } from '#app'
import { StorageSerializers } from '@vueuse/core'

type InferReturnType<F extends (...args: unknown[]) => unknown> = F extends (
  ...args: infer Args
) => infer R
  ? Awaited<R>
  : never

type HandlerArgsOrNever<Handler> = Handler extends (
  ...args: infer Args
) => unknown
  ? Args
  : never

// Copy from asyncData.d.ts
export type PickFrom<T, K extends Array<string>> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Array<any>
    ? T
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      T extends Record<string, any>
      ? keyof T extends K[number]
        ? T // Exact same keys as the target, skip Pick
        : K[number] extends never
          ? T
          : Pick<T, K[number]>
      : T
export type KeysOf<T> = Array<
  T extends T // Include all keys of union types, not just common keys
    ? keyof T extends string
      ? keyof T
      : never
    : never
>

/**
 * useAsyncData with cache, most parameters and return values are consistent with useAsyncData, support caching during ssr,
 * @notice Can only be run within the store
 * @notice all params should pass from `execute`
 * @notice Try not to call the request function inside `handler`, but directly assign the request function to the handler to prevent the key from being generated incorrectly.
 * @param {Handler} handler - The function to fetch data.
 * @param {AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>} [options] - Optional configuration for the async data fetching.
 * `deep` default value is `false`,`dedupe` default value is `defer`, options excludes `immediate` and `watch`
 * @param {number} [cacheTime=15000] - The time in milliseconds to cache the data. Defaults to 15000ms.
 * @returns {Object} return value excludes `refresh` and `pending`, the `execute` function has been enhanced and can be used to pass parameters to the `handler`.
 * @example
 * import { getOptionAccountListByAddress } from '@deorderbook/sdk'
 * const { data, execute, status, error } = useCacheableAsyncData(getOptionAccountListByAddress);
 * await execute(['0xb297b1156ad97ec041d93a3b2b4ec2d8e9c7db2f'])
 */
export function useCacheableAsyncData<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Handler extends (...args: any[]) => Promise<unknown>,
  ResT = InferReturnType<Handler>,
  DataT = ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = DataT,
>(
  handler: Handler,
  options?: Omit<
    AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>,
    'immediate' | 'watch'
  >,
  cacheTime: number = 15000,
) {
  type ConditionalHandlerArgs =
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    HandlerArgsOrNever<Handler> extends [] ? void : HandlerArgsOrNever<Handler>

  const getCacheKey = (args: ConditionalHandlerArgs) => {
    const _args =
      args?.length && args[0] !== undefined && args[0] !== null ? args : []
    return handler.toString() + JSON.stringify(_args)
  }

  const getCacheData = (key: string) => {
    return useLocalStorage<DefaultT | PickFrom<DataT, PickKeys>>(key, null, {
      serializer: StorageSerializers.object,
    })
  }
  const isCacheValid = (key: string, cacheTime: number) => {
    const cachedData = getCacheData(key)
    const lastFetchTime = useLocalStorage(`${key}_lastFetchTime`, 0)
    return (
      cachedData.value !== undefined &&
      cachedData.value !== null &&
      Date.now() - lastFetchTime.value < cacheTime
    )
  }
  // TODO: Encryption and prevention of manual modification
  const updateCache = (
    key: string,
    newData: DefaultT | PickFrom<DataT, PickKeys>,
  ) => {
    const cachedData = getCacheData(key)
    const lastFetchTime = useLocalStorage(`${key}_lastFetchTime`, 0)

    cachedData.value = newData
    lastFetchTime.value = Date.now()
  }

  /** global var for pass params to `handler` function */
  let _args: ConditionalHandlerArgs
  /** wrap function for `handler`, to pass args from `execute` function */
  const fetchData = async () => {
    const args = Array.isArray(_args) ? _args : []
    return await handler(...args)
  }

  const modifiedOptions = {
    deep: false,
    dedupe: 'defer',
    ...options,
    immediate: false,
    watch: undefined,
  }
  const {
    data,
    refresh,
    pending, // remove pending for nuxt4, https://github.com/nuxt/nuxt/pull/25864
    execute: _execute,
    ...returnObj
    // @ts-expect-error, expect error
  } = useAsyncData(fetchData, modifiedOptions)

  /**
   * Executes the data fetching process, utilizing caching to avoid unnecessary network requests.
   * This function first checks if the data is already cached and valid. If the data is valid and
   * not forced to refresh, it returns early. Otherwise, it proceeds to fetch the data, update the cache
   * @param {ConditionalHandlerArgs} args - The arguments to pass to the handler function.
   * @param {boolean} [force=false] - when value is true will ignoring the cache.
   * @returns {Promise<void>}
   */
  const execute = async (args: ConditionalHandlerArgs, force = false) => {
    _args = args
    const key = getCacheKey(args)
    if (!force && isCacheValid(key, cacheTime)) {
      // reuse cached data after reload page
      if (data.value === null) {
        const storageData = getCacheData(key)
        data.value = storageData.value
      }
      return
    }
    // @ts-expect-error, hack way to pass args of handle
    await _execute(...(_args ?? []))

    // FIXME: when cache is found invalid, _execute function is not executed AT ALL when first-loading DOM
    if (data.value === null) {
      const res = await fetchData()
      // @ts-expect-error, force update data.value with returned value from fetchData() function
      data.value = res
    }
    updateCache(key, data.value)
  }

  return {
    data,
    ...returnObj,
    execute,
  }
}
