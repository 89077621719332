import { defineStore } from 'pinia'
import { Big } from 'big.js'
import dayjs from 'dayjs'
import { gql } from '@urql/core'
import { onWalletReady } from '@base/composables/onWalletReady'
import type { BulletType } from '@deorderbook/shared'
import { useDerivedActiveBulletPrice } from '@base/composables/useDerivedActiveBulletPrice'

export interface FormattedToken {
  name: string
  symbol: string
  address: string
  priceUSD: string
}

export interface PriceCacheItem {
  priceUSD: string
  updatedAt: number
}

export interface GetBulletPriceParams {
  type: BulletType
  strikePrice: string // divided by 1e18
  exerciseTimestamp: string
}

interface TokenPrice {
  token: string
  avg_price_usd: number
}

const GET_TOKEN_PRICES = gql`
  query getTokenPrices {
    derived_token_price {
      token
      avg_price_usd
    }
  }
`
const initStoreState = () => {
  const loading = ref(false)
  const formattedTokens = ref<FormattedToken[]>([])
  // TODO: tmp hard code
  const tokenDOB = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUSDC = ref<FormattedToken>({
    priceUSD: '1',
  } as FormattedToken)
  const tokenSellToken = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenWETH = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenETH = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUHODL = ref<FormattedToken>({
    priceUSD: '1',
  } as FormattedToken)
  const tokenSellTokenHODL = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUBullet = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenSellTokenBullet = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUSniper = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenSellTokenSniper = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)

  const bulletPriceCacheMap = ref<Map<string, string>>(new Map())

  const updateDuration = 15
  return {
    loading,
    tokenDOB,
    tokenUSDC,
    tokenSellToken,
    tokenWETH,
    tokenETH,
    tokenUHODL,
    tokenSellTokenHODL,
    tokenUBullet,
    tokenSellTokenBullet,
    tokenUSniper,
    tokenSellTokenSniper,
    formattedTokens,
    bulletPriceCacheMap,
    updateDuration,
  }
}

export const useTokensStore = defineStore('tokens', () => {
  const { mainnetClient } = useBackendClient()
  const { data: bulletPriceData, refreshData: refreshBulletPrice } =
    useDerivedActiveBulletPrice()
  const {
    loading,
    tokenDOB,
    tokenUSDC,
    tokenSellToken,
    tokenWETH,
    tokenETH,
    tokenUHODL,
    tokenSellTokenHODL,
    tokenUBullet,
    tokenSellTokenBullet,
    tokenUSniper,
    tokenSellTokenSniper,
    formattedTokens,
    bulletPriceCacheMap,
    updateDuration,
  } = initStoreState()
  let updatedAt = 0

  onWalletReady(
    () => {
      actionRefreshTokensPrice()
    },
    {
      status: 'setup',
    },
  )

  const getTokenPriceFromList = (list: TokenPrice[], token: string) => {
    const price =
      list.find((price) => price.token === token)?.avg_price_usd ?? 0
    return Big(price).toFixed()
  }

  async function actionRefreshTokensPrice(force = false) {
    if (
      force ||
      (loading.value === false && dayjs().unix() - updatedAt >= updateDuration)
    ) {
      loading.value = true
      updatedAt = dayjs().unix()

      const { data } = await mainnetClient.value.query(GET_TOKEN_PRICES, {})
      const tokenPrices: TokenPrice[] = data?.derived_token_price ?? []

      tokenSellToken.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-wbtc',
      )
      tokenSellTokenHODL.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-wbtc',
      )
      tokenUSDC.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-usdc',
      )
      tokenUHODL.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-usdc',
      )
      tokenWETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      tokenETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      tokenDOB.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-dob')
      tokenETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      tokenWETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')

      await refreshBulletPrice()
      if (bulletPriceData.value) {
        bulletPriceData.value.forEach((price) =>
          bulletPriceCacheMap.value.set(price.bullet_id, price.price),
        )
      }
      loading.value = false
    }
  }

  /**
   * Map[key]price, eg: ["0x12412412..."]"12.324"
   * key: sniper address
   */
  const sniperPrice = ref(new Map<string, PriceCacheItem>())

  /**
   * @description Get Sniper price by address, type and strike price
   * @param type - uSNIPER or sellTokenSniper
   * @param strikePrice - Strike price (with decimals)
   * @param address - Sniper address
   * @returns PriceCacheItem ref
   */
  function getSniperPrice(
    type: 'uSNIPER' | 'sellTokenSNIPER',
    strikePrice: string,
    address: string,
  ): Ref<PriceCacheItem | undefined> {
    const target = sniperPrice.value.get(address)
    if (
      target === undefined ||
      (target && target.updatedAt + updateDuration <= dayjs().unix())
    ) {
      if (type === 'uSNIPER') {
        if (
          Big(divStrikePrice(strikePrice)).gt(tokenSellToken.value.priceUSD)
        ) {
          const price = (
            Number(tokenSellToken.value.priceUSD) /
            Big(divStrikePrice(strikePrice)).toNumber()
          ).toFixed()
          sniperPrice.value.set(address, {
            priceUSD: price,
            updatedAt: dayjs().unix(),
          })
        } else {
          sniperPrice.value.set(address, {
            priceUSD: '1',
            updatedAt: dayjs().unix(),
          })
        }
      } else if (type === 'sellTokenSNIPER') {
        if (
          Big(divStrikePrice(strikePrice)).gt(tokenSellToken.value.priceUSD)
        ) {
          sniperPrice.value.set(address, {
            priceUSD: tokenSellToken.value.priceUSD,
            updatedAt: dayjs().unix(),
          })
        } else {
          sniperPrice.value.set(address, {
            priceUSD: Big(divStrikePrice(strikePrice)).toFixed(),
            updatedAt: dayjs().unix(),
          })
        }
      }
    }
    return computed(() => {
      return sniperPrice.value.get(address)
    })
  }

  const getFormattedBulletId = (params: GetBulletPriceParams): string => {
    const { type, strikePrice, exerciseTimestamp } = params
    const { getOriginalSymbol } = useTokens()
    const bulletSymbol = getOriginalSymbol(type)
    const formatedBulletSymbol =
      bulletSymbol.slice(0, 2) + bulletSymbol.slice(2).toLowerCase()

    return `${formatedBulletSymbol}_${exerciseTimestamp}_${strikePrice}`
  }

  const getBulletPrice = (params: GetBulletPriceParams): string => {
    const bulletId = getFormattedBulletId(params)
    return bulletPriceCacheMap.value.get(bulletId) ?? '0'
  }

  return {
    loading,
    tokenDOB,
    tokenSellToken,
    tokenWETH,
    tokenETH,
    tokenUSDC,
    tokenUHODL,
    tokenSellTokenHODL,
    tokenUSniper,
    tokenSellTokenSniper,
    tokenUBullet,
    tokenSellTokenBullet,
    getSniperPrice,
    formattedTokens,
    actionRefreshTokensPrice,
    bulletPriceCacheMap,
    getBulletPrice,
  }
})
