export enum HODL {
  uHODL = 'uHODL',
  sellTokenHODL = 'sellTokenHODL',
}

export enum Wrapped {
  WETH = 'WETH',
}

export enum Bullet {
  sellTokenBULLET = 'sellTokenBULLET',
  uBULLET = 'uBULLET',
}

export enum Sniper {
  sellTokenSNIPER = 'sellTokenSNIPER',
  uSNIPER = 'uSNIPER',
}

export enum BaseToken {
  USDC = 'USDC',
  sellToken = 'sellToken',
  ETH = 'ETH',
}

export type WrappedToken = HODL | Wrapped
export enum NativeToken {
  DOB = 'DOB',
}

export type Token = BaseToken | HODL | Bullet | Sniper | NativeToken | Wrapped
export type BasicTokenType =
  | keyof typeof BaseToken
  | keyof typeof HODL
  | keyof typeof Wrapped
  | keyof typeof NativeToken

export type AllTokenType =
  | BasicTokenType
  | keyof typeof Sniper
  | keyof typeof Bullet
  | 'sniper'
  | 'bullet'
export type GetTokensInfoReturnType = BasicTokenType | 'DOBStakingPool'
