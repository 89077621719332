import { defineStore } from 'pinia'

export const useNotificationbarStore = defineStore('notificationBar', () => {
  const isSwitchNetworkBarVisible = ref(false)
  const isRegisterForBetaBarVisible = ref(true)
  const isNetworkDropdownOpen = ref()

  const showSwitchNetworkBar = () => {
    isSwitchNetworkBarVisible.value = true
  }

  const hideSwitchNetworkBar = () => {
    isSwitchNetworkBarVisible.value = false
  }

  const showRegisterForBetaBar = () => {
    isRegisterForBetaBarVisible.value = true
  }

  const hideRegisterForBetaBar = () => {
    isRegisterForBetaBarVisible.value = false
    sessionStorage.setItem('beta-alert-closed', 'true')
  }

  const openNetworkDropdown = () => {
    isNetworkDropdownOpen.value.handleOpen()
  }

  const closeNetworkDropdown = () => {
    isNetworkDropdownOpen.value.handleClose()
  }

  return {
    isSwitchNetworkBarVisible,
    showSwitchNetworkBar,
    hideSwitchNetworkBar,
    isRegisterForBetaBarVisible,
    showRegisterForBetaBar,
    hideRegisterForBetaBar,
    openNetworkDropdown,
    isNetworkDropdownOpen,
    closeNetworkDropdown,
  }
})
